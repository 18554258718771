export default {
  colors: {
    text: "#4E5963",
    headerBackground: "#0F4C81",
    headerText: "#1D2730",
    paperBackgroundColor: "#fff",
    paperHoverBackgroundColor: "#fff",
    paperHeadingColor: "#1D2730",
    paperHoverHeadingColor: "#1774C6",
    paperBorderColor: "transparent",
    paperDescriptionColor: "#4E5963",
    collectionBackgroundColor: "#F3F5F8",
    collectionHeading: "#1D2730",
    collectionDescription: "#4E5963",
    breadcrumbLinkTextColor: "#989FA5",
    breadcrumbHoverLinkTextColor: "#4E5963",
    breadcrumbTextColor: "#989FA5",
    articleTextColor: "#565867",
    articleDescriptionColor: "#565867",
    background: "#fff",
    primary: "#639",
    secondary: "#ff6347",
    muted: "#CBCED1",
    searchTextColor: "#000",
    searchTextFocusColor: "#1D2730",
    searchTextPlaceholderColor: "#4E5963",
    searchTextFocusPlaceholderColor: "#1D2730",
    comboboxColor: "#000",
    iconColor: "#85BFF1",
    logoColor: "fff",
    footerTextColor: "#a8b6c2",
    footerTextHoverColor: "#a8b6c2",
  },
  radii: [0, 2, 4, 8],
  fonts: {
    body: "Mukta Mahee, sans-serif",
    heading: "Montserrat Alternates, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },
  lineHeights: {
    small: 1.2,
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  /* Styles for MDX */
  styles: {
    // the keys used here reference elements in MDX
    h1: {
      // the style object for each element
      // can reference other values in the theme
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      marginTop: 0,
      marginBottom: 3,
      textTransform: "lowercase",
    },
    h2: {
      textTransform: "lowercase",
    },
    h3: {
      textTransform: "lowercase",
    },
    a: {
      color: "primary",
      textDecoration: "none",
      ":hover, :focus": {
        color: "secondary",
        textDecoration: "none",
      },
    },
    blockquote: {
      marginLeft: "1.75rem",
    },
    // more styles can be added as needed
  },
}
